import React, {useEffect} from 'react';
import bg from './bg1.png'
import './App.css';

function App() {
    return (
        <div onClick={downloadClick} className="App">
            <div>
                {/*<img className={"App-bg"} draggable="false" src={bg} alt=""/>*/}
            </div>
        </div>
    );
}

interface ClipboardEntity {
    inviteCode?: string,
    agentId?: string
}

const downloadClick = () => {
    console.log(window.location.search)
    const paramsString = window.location.search.split('?')[1];
    const paramMap = new Map();
    if (paramsString) {
        const params = paramsString.split("&");
        params.map(item => {
            const param = item.split('=');
            paramMap.set(param[0], param[1]);
        })
    }
    console.log(paramMap)

    if (navigator.clipboard && window.isSecureContext) {
        let json: ClipboardEntity = {
            inviteCode: paramMap.get("inviteCode"),
            agentId: paramMap.get("agentId")
        }
        navigator.clipboard.writeText(JSON.stringify(json))
            .then(() => {
                console.log('Text successfully copied to clipboard');
                const link = document.createElement('a');
                link.href = paramMap.get("downloadUrl")
                link.download = 'download.apk'; // 指定下载的文件名
                link.click(); // 自动点击链接以触发下载
            })
            .catch(err => {
                try {
                    const textArea = document.createElement('textarea')
                    textArea.value = JSON.stringify(json)
                    // 使text area不在viewport，同时设置不可见
                    document.body.appendChild(textArea)
                    textArea.focus()
                    textArea.select()
                    return new Promise((resolve, reject) => {
                        // 执行复制命令并移除文本框
                        document.execCommand('copy') ? resolve(null) : reject(new Error('出错了'))
                        textArea.remove()
                    }).then(
                        () => {
                            // alert("复制成功");
                            const link = document.createElement('a');
                            link.href = paramMap.get("downloadUrl")
                            link.download = 'download.apk'; // 指定下载的文件名
                            link.click(); // 自动点击链接以触发下载
                        },
                        (reason) => {
                            throw reason;
                        })
                } catch (e) {
                    alert("复制失败");
                    const link = document.createElement('a');
                    link.href = paramMap.get("downloadUrl")
                    link.download = 'download.apk'; // 指定下载的文件名
                    link.click(); // 自动点击链接以触发下载
                }

            });
    }

}

export default App;
